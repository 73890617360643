//Channels
export const CHANNEL_FETCH = "CHANNEL_FETCH";
export const CHANNEL_FETCH_UPDATE = "CHANNEL_FETCH_UPDATE";
export const CHANNEL_DELETE = "CHANNEL_DELETE";
export const CHANNELS_FETCH_INSERT = "CHANNELS_FETCH_INSERT";
export const CHANNELS_FETCH_ALL = "CHANNELS_FETCH_ALL";
export const CHANNELS_FETCH_USER_CHANNELS = "CHANNELS_FETCH_USER_CHANNELS";
export const CHANNELS_FETCH_LTI_CHANNELS = "CHANNELS_FETCH_LTI_CHANNELS";
export const CHANNEL_FETCH_MEMBERS = "CHANNEL_FETCH_MEMBERS";
export const CHANNEL_DELETE_MEMBER = "CHANNEL_DELETE_MEMBER";
export const CHANNELS_RESET = "CHANNELS_RESET";
export const CHANNELS_ADD_CHANNELS = "CHANNELS_ADD_CHANNELS";
export const CHANNELS_ADD_USER_CHANNELS = "CHANNELS_ADD_USER_CHANNELS";
export const CHANNELS_ADD_LTI_CHANNELS = "CHANNELS_ADD_LTI_CHANNELS";
export const CHANNELS_SET_USER_STATUS = "CHANNELS_SET_USER_STATUS";
export const CHANNELS_ADD_ACTIVITIES = "CHANNELS_ADD_ACTIVITIES";
export const CHANNELS_UPDATE = "CHANNELS_UPDATE";
export const CHANNELS_SET_RETRIEVED = "CHANNELS_SET_RETRIEVED";
export const CHANNELS_SET_MEMBERS = "CHANNELS_SET_MEMBERS";
export const CHANNELS_EDIT_JOIN_CODE = "CHANNELS_GENERATE_JOIN_CODE";
export const CHANNELS_SUBMIT_JOIN_CODE = "CHANNELS_SUBMIT_JOIN_CODE";
export const CHANNELS_JOIN = "CHANNELS_JOIN";
export const CHANNELS_LEAVE = "CHANNELS_LEAVE";
export const UPDATE_CHANNEL_MEMBERS_ROLE = "UPDATE_CHANNEL_MEMBERS_ROLE";
export const CHANNEL_FETCH_OWNER = "CHANNEL_FETCH_OWNER";
export const CHANNEL_SET_FETCHED_OWNER = "CHANNEL_SET_FETCHED_OWNER";
export const CHANNEL_FETCH_CONTENT = "CHANNEL_FETCH_CONTENT";
export const CHANNEL_ADD_COLLECTION = "CHANNEL_ADD_COLLECTION";
export const CHANNEL_REMOVE_COLLECTION = "CHANNEL_REMOVE_COLLECTION";
export const CHANNEL_ADD_BUNDLE = "CHANNEL_ADD_BUNDLE";
export const CHANNEL_REMOVE_BUNDLE = "CHANNEL_REMOVE_BUNDLE";
export const CHANNEL_SET_CONTENT = "CHANNEL_SET_CONTENT";
export const CHANNEL_SET_ADD_CONTENT_COLLECTION =
  "CHANNEL_SET_ADD_CONTENT_COLLECTION";
export const CHANNEL_SET_ADD_CONTENT_BUNDLE = "CHANNEL_SET_ADD_CONTENT_BUNDLE";
export const CHANNEL_SET_REMOVE_CONTENT_COLLECTION =
  "CHANNEL_SET_REMOVE_CONTENT_COLLECTION";
export const CHANNEL_SET_REMOVE_CONTENT_BUNDLE =
  "CHANNEL_SET_REMOVE_CONTENT_BUNDLE";
export const CHANNEL_REQUEST_MORE_USERS = "CHANNEL_REQUEST_MORE_USERS";
export const CHANNEL_SET_TOTAL_USERS = "CHANNEL_SET_TOTAL_USERS";
export const CHANNEL_INVITE_USERS = "CHANNEL_INVITE_USERS";
export const CHANNEL_ADD_LINKED_CHANNEL_SUBSCRIPTION =
  "CHANNEL_ADD_LINKED_CHANNEL_SUBSCRIPTION";
export const CHANNEL_GET_LINKED_CHANNELS = "CHANNEL_GET_LINKED_CHANNELS";
export const CHANNEL_SET_LINKED_CHANNELS = "CHANNEL_SET_LINKED_CHANNELS";
export const CHANNEL_DELETE_LINKED_CHANNEL_SUBSCRIPTION =
  "CHANNEL_DELETE_LINKED_CHANNEL_SUBSCRIPTION";
export const CHANNEL_REMOVE_CHANNEL_SUBSCRIPTION_FROM_STORE =
  "CHANNEL_REMOVE_CHANNEL_SUBSCRIPTION_FROM_STORE";
export const CHANNEL_EDIT_LINKED_CHANNEL_SUBSCRIPTION =
  "CHANNEL_EDIT_LINKED_CHANNEL_SUBSCRIPTION";
export const CHANNEL_SET_CHANNEL_APP = "CHANNEL_SET_CHANNEL_APP";
export const CHANNEL_REMOVE_CHANNEL_APP = "CHANNEL_REMOVE_CHANNEL_APP";
export const CHANNEL_ADD_IP_RANGE = "CHANNEL_ADD_IP_RANGE";
export const CHANNEL_UPDATE_IP_RANGE = "CHANNEL_UPDATE_IP_RANGE";
export const CHANNEL_DELETE_IP_RANGE = "CHANNEL_DELETE_IP_RANGE";

export function SetUpChannelFor_Redux(channel) {
  return {
    id: channel.PK,
    name: channel.channel_name,
    image_url: channel.image,
    banner_url: channel.banner,
    access_pattern: channel.access_pattern,
    embed_permission: channel.embed_permission,
    join_code: channel.join_code,
    join_code_status: channel.join_code_status,
    view_collections: channel.view_collections,
    user_limit: channel.user_limit,
    total_users: channel.total_users,
    subscriptions: channel.subscriptions,
    simple_access: channel.simple_access,
    ip_ranges: channel.ip_ranges
  };
}

export function SetUpSubscriptionFor_Redux(subscription) {
  let result = {};

  result.id = subscription.SK;
  result.subscriberId = subscription.PK;
  result.canAuthor = subscription.can_create;
  result.canEdit = subscription.can_edit;
  result.expiration = subscription.expiration;
  result.name = subscription.name;
  result.image = subscription.image;
  result.activities = subscription.activities;
  result.isExpired =
    Date.now() > new Date(subscription.expiration).getTime() ? true : false;

  return result;
}
