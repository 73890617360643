import React, { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Form,
  Button,
  Alert,
  Row,
  Container,
  Col,
  Image,
  Spinner,
  Dropdown,
} from "react-bootstrap";
import { Hidden, TextField, makeStyles } from "@material-ui/core";

import { Button as ButtonMdc } from "@rmwc/button";
import "@rmwc/button/styles";
import { TextField as TextFieldMdc } from "@rmwc/textfield";
import "@rmwc/textfield/styles";
import { IconButton } from "@rmwc/icon-button";
import "@rmwc/icon-button/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemMeta,
} from "@rmwc/list";
import "@rmwc/list/styles";
import { Typography } from "@rmwc/typography"; // Using SASS in main. No CSS needed.

import AccountWrapper from "../../components/AccountWrapper";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import Error from "../../components/Error/Error";
import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import { CHANNEL_EMBED_TYPES } from "../../constants/channelEmbedTypes";
import { CHANNEL_JOIN_CODE_TYPES } from "../../constants/channelJoinCodeTypes";
import { CHANNEL_MODELS_PERMISSION_TYPES } from "../../constants/channelModelsPermissionTypes";
import { CHANNEL_SIMPLE_ACCESS_TYPES } from "../../constants/channelSimpleAccessTypes";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useChannel } from "../../hooks/Channel/useChannel";
import { useChannelAccessPattern } from "../../hooks/Channel/useChannelAccessPattern";
import Actions from "../../Redux/actions";
import JoinCodeDisplay from "../../components/JoinCodeDisplay/JoinCodeDisplay";
import { SectionHeadlineRow } from "../../components/Layout/Headings";
import { ExportResources } from "../../components/ExportResources/ExportResources";
import { IPRangesTable } from "../../components/IPRangesTable/IPRangesTable";
import AddIpRangeDialog from "../../components/Dialog/AddIpRangeDialog/AddIpRangeDialog";
import EditIpRangeDialog from "../../components/Dialog/EditIpRangeDialog/EditIpRangeDialog";
import { snackbarQueue } from "../../components/Snackbar/snackbarQueue";
import { dialogQueue } from "../../components/Dialog/dialogQueue";
import { useChannelIPRanges } from "../../hooks/Channel/useChannelIPRanges";

const useStyles = makeStyles({
  textField: {
    width: "100%",
  },
});

const AccountChannelEditItem = ({
  validated,
  validateForm,
  channelData,
  name,
  setName,
  setProfilePhoto,
  setBannerPhoto,
  error,
  setError,
  setAccessPattern,
  accessPattern,
  embedPermission,
  setEmbedPermission,
  embedWhiteList,
  setEmbedWhiteList,
  joinPermission,
  setJoinPermission,
  joinCode,
  processing,
  viewCollections,
  setViewCollections,
  simpleAccess,
  setSimpleAccess,
  ipRanges,
  setIPRanges
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { channelId } = useParams();

  const { role } = useChannelRole(channelId);
  const [canDeleteChannel, setCanDeleteChannel] = useState(false);
  const [domainToAdd, setDomainToAdd] = useState("");
  const [domainIsValid, setDomainIsValid] = useState(null);
  const [domainInvalidMessage, setDomainInvalidMessage] = useState("");
  const [openAddIpRangeDialog, setOpenAddIpRangeDialog] = useState(false);
  const [openEditIpRangeDialog, setOpenEditIpRangeDialog] = useState(false);
  const [editingIpRange, setEditingIpRange] = useState(null);

  useEffect(() => {
    if (role === "owner") {
      setCanDeleteChannel(true);
    } else {
      setCanDeleteChannel(false);
    }
  }, [role]);

  const domainRegex =
    /^(?=^.{1,253}$)(([a-zA-Z\d]([a-zA-Z\d-]{0,62}[a-zA-Z\d])*[\\.]){1,127}[a-zA-z]{2,63})$/;

  const renderAddDomainInput = () => {
    const inputLabel = "Domain";
    const inputName = "domain";
    const inputType = "text";
    // eslint-disable-next-line no-unused-vars
    const inputPattern =
      /^(?=^.{1,253}$)(([a-zA-Z\d]([a-zA-Z\d-]{0,62}[a-zA-Z\d])*[\\.]){1,127}[a-zA-z]{2,63})$/;
    return (
      <TextFieldMdc
        label={inputLabel}
        name={inputName}
        trailingIcon={{
          icon: "add_circle",
          onClick: () => addDomainToList(domainToAdd),
          // disabled: !domainIsValid
        }}
        value={domainToAdd}
        onChange={(evt) => setDomainToAdd(evt.target.value)}
        onBlur={(evt) => validateDomainToAdd(evt.target.value)}
        helpText={{
          validationMsg: true,
          persistent: true,
          children: domainIsValid
            ? "Enter the domain only. Do not include http:// or https:// before the domain."
            : domainInvalidMessage,
        }}
        invalid={domainIsValid === null ? undefined : !domainIsValid}
        // pattern={inputPattern}
        type={inputType}
      />
    );
  };

  const renderDomainList = () => {
    return (
      <div>
        <Typography use="subtitle2" tag="p">
          Allowed Domains
        </Typography>
        {renderAddDomainInput()}
        <List>
          {embedWhiteList.map((d) => (
            <ListItem key={d}>
              <ListItemText>
                <ListItemPrimaryText>{d}</ListItemPrimaryText>
              </ListItemText>
              <ListItemMeta>
                <IconButton
                  label="Remove domain"
                  icon="delete"
                  role="button"
                  onClick={() => removeDomainFromList(d)}
                />
              </ListItemMeta>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  const validateDomainToAdd = (domainToAdd) => {
    if (domainRegex.test(domainToAdd)) {
      setDomainInvalidMessage("");
      setDomainIsValid(true);
    } else {
      setDomainInvalidMessage("Enter a valid host domain.");
      setDomainIsValid(false);
    }
  };

  const addDomainToList = (domainToAdd) => {
    validateDomainToAdd(domainToAdd);

    if (domainIsValid) {
      const newDomainArray = [...embedWhiteList, domainToAdd];

      setEmbedWhiteList(newDomainArray);
      setDomainToAdd("");
    } else return;
  };

  const removeDomainFromList = (domainToRemove) => {
    const newDomainArray = [...embedWhiteList];
    const index = newDomainArray.indexOf(domainToRemove, 0);
    if (index > -1) {
      newDomainArray.splice(index, 1);
    }
    setEmbedWhiteList(newDomainArray);
  };

  const formatAccessPattern = () => {
    switch (accessPattern) {
      case "private":
        return "Private";
      case "hidden":
        return "Unlisted";
      default:
        return "?";
    }
  };

  const formatEmbedPermission = (type) => {
    switch (type) {
      case CHANNEL_EMBED_TYPES.ANYWHERE:
        return "Anywhere";
      case CHANNEL_EMBED_TYPES.DISABLED:
        return "Nowhere";
      case CHANNEL_EMBED_TYPES.WHITE_LIST:
        return "Specific sites";
      default:
        return CHANNEL_EMBED_TYPES.ANYWHERE;
    }
  };

  const formatJoinPermission = (type) => {
    switch (type) {
      case CHANNEL_JOIN_CODE_TYPES.DISABLED:
        return "Disabled";
      case CHANNEL_JOIN_CODE_TYPES.ENABLED:
        return "Enabled";
      case CHANNEL_JOIN_CODE_TYPES.REQUIRES_APPROVAL:
        return "Requires approval";
      default:
        return CHANNEL_JOIN_CODE_TYPES.DISABLED;
    }
  };

  const formatModelsPermission = (type) => {
    switch (type) {
      case CHANNEL_MODELS_PERMISSION_TYPES.DISABLED:
        return "Disabled";
      case CHANNEL_MODELS_PERMISSION_TYPES.ENABLED:
        return "Enabled";
      default:
        return CHANNEL_MODELS_PERMISSION_TYPES.DISABLED;
    }
  };

  const formatSimpleAccess = (type) => {
    switch (type) {
      case CHANNEL_SIMPLE_ACCESS_TYPES.DISABLED:
        return "Disabled";
      case CHANNEL_SIMPLE_ACCESS_TYPES.PUBLIC:
        return "Public";
      case CHANNEL_SIMPLE_ACCESS_TYPES.IP_RANGE:
        return "IP Range";
      default:
        return "Disabled";
    }
  };

  const userLimitReached = (channel) => {
    if (!channel.user_limit || !channel.total_users) {
      // not found
      console.warn(
        "This channel didn't have a user limit or total users: ",
        channel
      );
    }
    if (channel.total_users >= channel.user_limit) {
      return true;
    } else {
      return false;
    }
  };

  const fireAddIpRangeDialog = () => {
    setOpenAddIpRangeDialog(true);
  };

  const onCloseAddIpRangeDialog = (evt) => {
    setOpenAddIpRangeDialog(false);
  };

  const onClickEditRange = (ipRange) => {
    setEditingIpRange(ipRange);
    setOpenEditIpRangeDialog(true);
  };
  
  const onClickDeleteRange = (ipRange) => {
    dialogQueue
    .confirm({
      title: "Delete IP Range?",
      body: `Are you sure you want to delete the IP range for ${ipRange.name}? This action can't be undone.`,
      acceptLabel: "Delete",
    })
    .then((success) => {
      if (success) {
        let callback = (result) => {
          if (result.success) {
            snackbarQueue.notify({
              title: <b>Success!</b>,
              body: `IP range was deleted`,
            });
          } else {
            snackbarQueue.notify({
              title: <b>Uh oh!</b>,
              body: "Unable to delete IP range",
            });
          }
        };
       
        dispatch(
          Actions.Channels.deleteIpRangeToChannel(
            channelId,       
            ipRange.id,
            callback
          )
        );
      }
    });    
  }

  const onCloseEditIpRangeDialog = (evt) => {
    setOpenEditIpRangeDialog(false);
  };

  const renderAddIpRangeDialog = () => {
    return (
      <AddIpRangeDialog
        open={openAddIpRangeDialog}
        onClose={onCloseAddIpRangeDialog}
        channelId={channelId}
        renderToPortal
      />
    );
  };

  const renderEditIpRangeDialog = () => {
    if (!editingIpRange) {
      return null;
    }

    return (
      <EditIpRangeDialog
        open={openEditIpRangeDialog}
        onClose={onCloseEditIpRangeDialog}
        channelId={channelId}
        id={editingIpRange.id}
        referenceId={editingIpRange.referenceId}
        name={editingIpRange.name}
        range={editingIpRange.ranges}
        start={editingIpRange.start}
        end={editingIpRange.end}
        renderToPortal
      />
    );
  };

  const renderJoinCodePermissionMessage = (permission) => {
    if (permission === CHANNEL_JOIN_CODE_TYPES.DISABLED) {
      return (
        <Typography use="body1" tag="p">
          Join code is disabled for this channel.{" "}
        </Typography>
      );
    } else {
      return (
        <>
          <Typography use="body1" tag="p">
            Users may join this channel using the join code.
          </Typography>
          {joinCode ? (
            <JoinCodeDisplay
              channelData={channelData}
              userLimitReached={userLimitReached(channelData)}
            />
          ) : null}
        </>
      );
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        // onSubmit={validateForm}
        encType="multipart/form-data"
        className="ActivitiesAddNew"
      >
        <Container fluid>
          <Row>
            <Col xs={8} className="ActivitiesAddNew__title">
              {/* <p>Edit Channel</p> */}
              <SectionHeadlineRow
                title="Edit Channel"
                tag="h1"
                typographyStyle="headline6"
              />
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={6} />
                <Col xs={6} className="ActivitiesAddNew__submit">
                  <Button
                    variant="primary"
                    type="button"
                    className="x-center"
                    onClick={validateForm}
                  >
                    {!processing && <span>Update</span>}
                    {processing && (
                      <span>
                        <Spinner animation="grow" />
                      </span>
                    )}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Row>
                <Col xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Name"
                    multiline
                    error={validated}
                    rows="2"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    placeholder="Enter name of the channel"
                    value={name}
                    onChange={(evt) => setName(evt.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forJoinCodePermission">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Join code"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle id="join-code-permission">
                        {formatJoinPermission(joinPermission)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={() =>
                            setJoinPermission(CHANNEL_JOIN_CODE_TYPES.DISABLED)
                          }
                          active={
                            joinPermission === CHANNEL_JOIN_CODE_TYPES.DISABLED
                          }
                        >
                          {formatJoinPermission(
                            CHANNEL_JOIN_CODE_TYPES.DISABLED
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() =>
                            setJoinPermission(CHANNEL_JOIN_CODE_TYPES.ENABLED)
                          }
                          active={
                            joinPermission === CHANNEL_JOIN_CODE_TYPES.ENABLED
                          }
                        >
                          {formatJoinPermission(
                            CHANNEL_JOIN_CODE_TYPES.ENABLED
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  {renderJoinCodePermissionMessage(joinPermission)}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forModelsPermission">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Show models tab in channel"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle id="models-permission">
                        {formatModelsPermission(viewCollections)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={() =>
                            setViewCollections(
                              CHANNEL_MODELS_PERMISSION_TYPES.DISABLED
                            )
                          }
                          active={
                            viewCollections ===
                            CHANNEL_MODELS_PERMISSION_TYPES.DISABLED
                          }
                        >
                          {formatModelsPermission(
                            CHANNEL_MODELS_PERMISSION_TYPES.DISABLED
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() =>
                            setViewCollections(
                              CHANNEL_MODELS_PERMISSION_TYPES.ENABLED
                            )
                          }
                          active={
                            viewCollections ===
                            CHANNEL_MODELS_PERMISSION_TYPES.ENABLED
                          }
                        >
                          {formatModelsPermission(
                            CHANNEL_MODELS_PERMISSION_TYPES.ENABLED
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forEmbedPermission">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Embed access"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    {/* <Form.Text>Where can activities from this channel be embedded?</Form.Text> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        id="channel-embed-permission"
                        disabled={!setEmbedPermission}
                      >
                        {formatEmbedPermission(embedPermission)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={() =>
                            setEmbedPermission(CHANNEL_EMBED_TYPES.ANYWHERE)
                          }
                          active={
                            embedPermission === CHANNEL_EMBED_TYPES.ANYWHERE
                          }
                        >
                          {formatEmbedPermission(CHANNEL_EMBED_TYPES.ANYWHERE)}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() =>
                            setEmbedPermission(CHANNEL_EMBED_TYPES.DISABLED)
                          }
                          active={
                            embedPermission === CHANNEL_EMBED_TYPES.DISABLED
                          }
                        >
                          {formatEmbedPermission(CHANNEL_EMBED_TYPES.DISABLED)}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() =>
                            setEmbedPermission(CHANNEL_EMBED_TYPES.WHITE_LIST)
                          }
                          active={
                            embedPermission === CHANNEL_EMBED_TYPES.WHITE_LIST
                          }
                        >
                          {formatEmbedPermission(
                            CHANNEL_EMBED_TYPES.WHITE_LIST
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  {embedPermission === CHANNEL_EMBED_TYPES.WHITE_LIST ? (
                    <>{renderDomainList()}</>
                  ) : embedPermission === CHANNEL_EMBED_TYPES.DISABLED ? (
                    <p>Embedding activities is disabled on all websites.</p>
                  ) : embedPermission === CHANNEL_EMBED_TYPES.ANYWHERE ? (
                    <p>Embedding activities is allowed on any website.</p>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forSimpleAccess">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Simple access"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle
                        id="channel-simple-access"
                        disabled={!setSimpleAccess}
                      >
                        {formatSimpleAccess(simpleAccess)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={() =>
                            setSimpleAccess(
                              CHANNEL_SIMPLE_ACCESS_TYPES.DISABLED
                            )
                          }
                          active={
                            simpleAccess ===
                            CHANNEL_SIMPLE_ACCESS_TYPES.DISABLED
                          }
                        >
                          {formatSimpleAccess(
                            CHANNEL_SIMPLE_ACCESS_TYPES.DISABLED
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() =>
                            setSimpleAccess(CHANNEL_SIMPLE_ACCESS_TYPES.PUBLIC)
                          }
                          active={
                            simpleAccess === CHANNEL_SIMPLE_ACCESS_TYPES.PUBLIC
                          }
                        >
                          {formatSimpleAccess(
                            CHANNEL_SIMPLE_ACCESS_TYPES.PUBLIC
                          )}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() =>
                            setSimpleAccess(
                              CHANNEL_SIMPLE_ACCESS_TYPES.IP_RANGE
                            )
                          }
                          active={
                            simpleAccess ===
                            CHANNEL_SIMPLE_ACCESS_TYPES.IP_RANGE
                          }
                        >
                          {formatSimpleAccess(
                            CHANNEL_SIMPLE_ACCESS_TYPES.IP_RANGE
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  {simpleAccess === CHANNEL_SIMPLE_ACCESS_TYPES.IP_RANGE ? (
                    <>
                      <ButtonMdc
                        label="Add IP Range"
                        outlined
                        icon="add"
                        type="button"
                        onClick={() => fireAddIpRangeDialog()}
                      />
                      <IPRangesTable
                        ip_ranges={ipRanges}
                        onClickEditRange={onClickEditRange}
                        onClickDeleteRange={onClickDeleteRange}
                      />
                    </>
                  ) : simpleAccess === CHANNEL_SIMPLE_ACCESS_TYPES.DISABLED ? (
                    <p>Simple channel access is disabled on all websites.</p>
                  ) : simpleAccess === CHANNEL_SIMPLE_ACCESS_TYPES.PUBLIC ? (
                    <p>Simple channel access is allowed on any website.</p>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col xs={4}>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forIsPublic">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Visibility"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    <Dropdown>
                      <Dropdown.Toggle id="channel-visibility">
                        {formatAccessPattern()}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onSelect={() => setAccessPattern("private")}
                          active={accessPattern === "private"}
                        >
                          Private
                        </Dropdown.Item>
                        <Dropdown.Item
                          onSelect={() => setAccessPattern("hidden")}
                          active={accessPattern === "hidden"}
                        >
                          Unlisted
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
                <Col xs={12} className="ActivitiesAddNew__choose">
                  <Form.Group controlId="forThumbnail">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Thumbnail (200px x 200px)"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setProfilePhoto(e.target.files[0])}
                    />
                  </Form.Group>
                  {channelData && channelData.profilePhoto && (
                    <Image src={channelData.profilePhoto} thumbnail />
                  )}
                </Col>
                <Col xs={12} className="ActivitiesAddNew__choose">
                  <Form.Group controlId="forBanner">
                    <Form.Label>
                      <SectionHeadlineRow
                        title="Banner (2560px x 1440px)"
                        tag="div"
                        typographyStyle="subtitle1"
                      />
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(e) => setBannerPhoto(e.target.files[0])}
                    />
                  </Form.Group>
                  {channelData && channelData.bannerPhoto && (
                    <Image src={channelData.bannerPhoto} thumbnail />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <ExportResources />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {error && (
              <Col xs={12}>
                <Alert
                  variant="danger"
                  onClose={() => setError(false)}
                  dismissible
                >
                  <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
                  <p>We have a error while trying to update the Channel.</p>
                </Alert>
              </Col>
            )}
          </Row>
        </Container>
      </Form>

      {canDeleteChannel ? (
        <div
          style={{
            display: "flex",
            flexGrow: "1",
            justifyContent: "flex-end",
            padding: "24px",
          }}
        >
          <ButtonMdc
            label="Delete Channel"
            danger
            outlined
            icon="delete"
            onClick={() =>
              history.push(`/account/channels/delete/${channelId}`)
            }
          />
        </div>
      ) : null}

      {renderAddIpRangeDialog()}
      {renderEditIpRangeDialog()}
    </>
  );
};

const AccountChannelEdit = ({ match }) => {
  const channelId = match.params.channelId;
  const history = useHistory();

  const { role } = useChannelRole(channelId);
  const { access_pattern } = useChannelAccessPattern(channelId);
  const { channel } = useChannel(channelId);
  const {channelIPRanges } = useChannelIPRanges(channelId);  

  const dispatch = useDispatch();

  const fetchUpdateChannel = (data, cb) =>
    dispatch(Actions.Channels.fetchUpdateChannel(data, cb));

  const uploadFile = (file, folder, cb) => {
    if (file) {
      dispatch(Actions.Universal.upload({ file, folder }, cb));
    } else {
      cb("");
    }
  };

  const [processing, setProcessing] = useState(false);

  const [name, setName] = useState("");
  const [accessPattern, setAccessPattern] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [embedPermission, setEmbedPermission] = useState(null);
  const [embedWhiteList, setEmbedWhiteList] = useState(null);
  const [joinPermission, setJoinPermission] = useState(null);
  const [joinCode, setJoinCode] = useState(null);
  const [viewCollections, setViewCollections] = useState(null);
  const [simpleAccess, setSimpleAccess] = useState(null);
  const [ipRanges, setIPRanges] = useState([]);
  const [bannerPhoto, setBannerPhoto] = useState("");
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ready, setReady] = useState(false);
  const [authorized, setAuthorized] = useState(true);  

  useEffect(() => {
    if (ready) return; //This effect only takes care of waiting for everything to get setup

    if (channel && role && access_pattern) {      
      if (role === "owner" || role === "admin") {
        setAuthorized(true);
      }

      setName(channel.name);
      setAccessPattern(access_pattern);
      setProfilePhoto(channel.profilePhoto);
      setBannerPhoto(channel.bannerPhoto);

      // Set embed permissions if found
      if (channel.embed_permission) {
        if (channel.embed_permission.permission) {
          setEmbedPermission(channel.embed_permission.permission);
        } else {
          setEmbedPermission(CHANNEL_EMBED_TYPES.ANYWHERE);
        }
        if (channel.embed_permission.domains_allowed) {
          setEmbedWhiteList(channel.embed_permission.domains_allowed);
        } else {
          setEmbedWhiteList([]);
        }
      } else {
        setEmbedPermission(CHANNEL_EMBED_TYPES.ANYWHERE);
        setEmbedWhiteList([]);
      }

      // Set join code permission if found
      if (channel.join_code_status) {
        setJoinPermission(channel.join_code_status);
      } else {
        // If no permission is set, we assume it is disabled.
        setJoinPermission(CHANNEL_JOIN_CODE_TYPES.DISABLED);
      }
      // Set join code if available
      if (channel.join_code) {
        setJoinCode(channel.join_code);
      }

      // Set model permission if found
      if (channel.view_collections) {
        setViewCollections(channel.view_collections);
      } else {
        // If no permission is set, we assume it is disabled.
        setViewCollections(CHANNEL_MODELS_PERMISSION_TYPES.DISABLED);
      }

      if (channel.simple_access) {
        setSimpleAccess(channel.simple_access);
      } else {
        setSimpleAccess(CHANNEL_SIMPLE_ACCESS_TYPES.DISABLED);
      }

      if (channel.ip_ranges) {
        setIPRanges(channel.ip_ranges);
      } else {
        setIPRanges([]);
      }

      setReady(true);
    }
  }, [channel, ready, access_pattern, role, channelIPRanges, channelIPRanges.length]);

  useEffect(() => {
    if (channelIPRanges) {
      setIPRanges(channelIPRanges);
    } else {
      setIPRanges([]);
    }       
  }, [channelIPRanges, channelIPRanges.length]);

  const validateForm = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      return setValidated(true);
    }
    setValidated(true);
    setProcessing(true);

    uploadFile(profilePhoto, "Thumbnails", (newProfilePhotoName) => {
      uploadFile(bannerPhoto, "Thumbnails", (newBannerPhotoName) => {
        fetchUpdateChannel(
          {
            name,
            profilePhoto: newProfilePhotoName,
            bannerPhoto: newBannerPhotoName,
            access_pattern: accessPattern,
            uid: channel.id,
            embed_permission: {
              permission: embedPermission,
              domains_allowed: embedWhiteList,
            },
            join_code_status: joinPermission,
            view_collections: viewCollections,
            simple_access: simpleAccess,
            ip_ranges: ipRanges
          },
          (resp) => {
            if (resp.success) {
              return history.push(`/channel/${channel.id}`);
            } else {
              setErrorMessage("Something went wrong: " + resp.msg);
              return setError(true);
            }
          }
        );
      });
    });
  };

  const renderContent = () => {
    if (error) {
      return <Error message={errorMessage} />;
    } else if (!authorized) {
      return (
        <Error message="You do not have permisssion to edit the channel" />
      );
    } else if (ready) {
      return (
        <AccountChannelEditItem
          validated={validated}
          validateForm={validateForm}
          channelData={channel}
          name={name}
          setName={setName}
          setProfilePhoto={setProfilePhoto}
          setBannerPhoto={setBannerPhoto}
          error={error}
          setError={setError}
          setAccessPattern={setAccessPattern}
          accessPattern={accessPattern}
          processing={processing}
          setProcessing={setProcessing}
          embedPermission={embedPermission}
          setEmbedPermission={setEmbedPermission}
          embedWhiteList={embedWhiteList}
          setEmbedWhiteList={setEmbedWhiteList}
          joinPermission={joinPermission}
          setJoinPermission={setJoinPermission}
          joinCode={joinCode}
          viewCollections={viewCollections}
          setViewCollections={setViewCollections}
          simpleAccess={simpleAccess}
          setSimpleAccess={setSimpleAccess}
          ipRanges={ipRanges}
          setIPRanges={setIPRanges}
        />
      );
    } else {
      return <PageLoadingIndicator />;
    }
  };

  return (
    <Fragment>
      <Hidden smUp>
        <AccountWrapper
          title="Edit channel"
          closeHandle={() => history.push("/account/channels")}
        >
          {renderContent()}
        </AccountWrapper>
      </Hidden>
      <Hidden xsDown>
        <PageWrapper>{renderContent()}</PageWrapper>
      </Hidden>
    </Fragment>
  );
};

export default AccountChannelEdit;
