import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableCell,
  DataTableBody,
} from "@rmwc/data-table";
import "@rmwc/data-table/styles";
import { Typography } from "@rmwc/typography";
import { Button } from "@rmwc/button";
import "@rmwc/button/styles";
import "@rmwc/menu/styles";
import "@rmwc/icon/styles";
import style from "./IPRangesTable.module.scss";

export const IPRangesTable = ({ ip_ranges, onClickEditRange, onClickDeleteRange }) => {
  const [sortColumn, setSortColumn] = useState("name");
  const [sortDirection, setSortDirection] = useState(1); // null = unsorted, 1 = ascending, -1 = descending
  const [sortColumnSecondary, setSortColumnSecondary] = useState(1);
  const [list, setList] = useState([]);

  // This effect should watch for changes to the available resources list, the sort column, and sort direction. It will produce a sorted list to be displayed by the component.
  useEffect(() => {
    /// Define inline functions first
    /**
     * @param { string } key Required. The key of the primary sort column
     * @param { -1 | 1 | null } sortDir Required. 1 = ascending, -1 = descending, null = unsorted
     * @param { string } secondaryKey Optional. The key of the secondary sort column
     */
    const compareValues = (key, sortDir, secondaryKey) => {
      return function (a, b) {
        let comparison = 0;      

        // Handle primary sort
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }
        const varA = typeof a[key] === "string" ? a[key].toLowerCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toLowerCase() : b[key];      

        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        } else {
          // Handle secondary sort
          if (
            !a.hasOwnProperty(secondaryKey) ||
            !b.hasOwnProperty(secondaryKey)
          ) {
            return 0;
          }
          const varA2 =
            typeof a[secondaryKey] === "string"
              ? a[secondaryKey].toLowerCase()
              : a[secondaryKey];
          const varB2 =
            typeof b[secondaryKey] === "string"
              ? b[secondaryKey].toLowerCase()
              : b[secondaryKey];              

          if (varA2 > varB2) {
            comparison = 1;
          }
          if (varA2 < varB2) {
            comparison = -1;
          }
        }

        // Return the comparison result with the proper sort order
        return sortDir === -1 ? comparison * -1 : comparison;
      };
    };

    if (ip_ranges) {
      const listMapped = ip_ranges.map((r) => {
        const range = { ...r };
        return range;
      });
      // If both sortColumn and sortDirection are defined, sort array and set state
      if (sortColumn && sortDirection) {
        const listSorted = listMapped.sort(
          compareValues(sortColumn, sortDirection, sortColumnSecondary)
        );  
        setList( listSorted );
        // Else just set state to the unsorted list
      } else {
        setList(listMapped);
      }
    }
  }, [ip_ranges, sortColumn, sortDirection, sortColumnSecondary]);

  const headers = [
    {
      id: 1,
      name: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: 2,
      name: "referenceId",
      label: "Reference ID",
      sortable: true,
    },
    {
      id: 3,
      name: "ranges",
      label: "Ranges",
      sortable: true,
    },
    {
      id: 4,
      name: "start",
      label: "Start",
      sortable: true,
    },
    {
      id: 5,
      name: "end",
      label: "End",
      sortable: true,
    },
    {
      id: 6,
      name: "actions",
      label: "Actions",
      sortable: false,
    },
  ];

  const handleOnSortChange = (column, sortDir) => {
    setSortColumn(column);
    setSortDirection(sortDir);

    if (column === "start" || column === "end") {
      setSortColumnSecondary("name");
    }
  };

  const onEditRange = (ipRange) => {
    onClickEditRange(ipRange);
  };

  const onDeleteIPRange = (ipRange) => {
    onClickDeleteRange(ipRange);
  };

  const renderName = (name) => {
    return (
      <div className={style.channelContainer}>
        <div className={style.channelNameContainer}>
          <Typography use="body1" tag="div" className={style.channelName}>
            {name ? name : "Untitled"}
          </Typography>
        </div>
      </div>
    );
  };

  const renderRanges = (ranges) => {
    return (
      <div className={style.channelContainer}>
        <div>
          {ranges.split(",").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const renderTableHeaders = () => {
    return headers.map((i) => {
      if (i.sortable) {
        return (
          <DataTableHeadCell
            key={`cs-${i.name}-${i.id}`}
            sort={sortColumn === i.name ? sortDirection : null}
            onSortChange={(sortDir) => handleOnSortChange(i.name, sortDir)}
          >
            {i.label}
          </DataTableHeadCell>
        );
      } else {
        return (
          <DataTableHeadCell
            key={`cs-${i.name}-${i.id}`}
            alignEnd={(i.name = "actions")}
          >
            {i.label}
          </DataTableHeadCell>
        );
      }
    });
  };

  const renderTableRows = () => {
    if (!list) {
      return null;
    }
    if (list.length === 0) {
      return (
        <DataTableRow>
          <DataTableCell>
            <div className={style.tableName}>
              There are no IP ranges set for this channel.
            </div>
          </DataTableCell>
          <DataTableCell />
          <DataTableCell />
          <DataTableCell />
          <DataTableCell />
        </DataTableRow>
      );
    }

    const rows = list.map((s) => {
      const startDateFormatted = new Date(s.start).toLocaleDateString();
      const endDateFormatted = new Date(s.end).toLocaleDateString();

      return (
        <DataTableRow key={s.id}>
          <DataTableCell alignStart>{renderName(s.name)}</DataTableCell>
          <DataTableCell alignMiddle>{s.referenceId}</DataTableCell>
          <DataTableCell alignStart>{renderRanges(s.ranges)}</DataTableCell>
          <DataTableCell alignEnd>{startDateFormatted}</DataTableCell>
          <DataTableCell alignEnd>{endDateFormatted}</DataTableCell>
          <DataTableCell>
            <div className={style.actionsContainer}>
              <Button
                type="button"
                label="Edit"
                onClick={() => onEditRange(s)}
              />
              <Button
                type="button"
                label="Delete"
                onClick={() => onDeleteIPRange(s)}
              />
            </div>
          </DataTableCell>
        </DataTableRow>
      );
    });
    return rows;
  };

  const renderContent = () => {
    return (
      <>
        <DataTable className={style.tableContainer} stickyRows={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>{renderTableHeaders()}</DataTableRow>
            </DataTableHead>
            <DataTableBody>{renderTableRows()}</DataTableBody>
          </DataTableContent>
        </DataTable>
      </>
    );
  };

  return <div className={style.wrapper}>{renderContent()}</div>;
};
