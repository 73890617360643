import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Card, CardPrimaryAction, CardMedia } from "@rmwc/card";
import "@rmwc/card/styles";
import { Typography } from "@rmwc/typography"; // Sass included in main. CSS not needed.

import style from "./SimpleGenericCard.module.scss";

import globalStyle from "../../../styles/global.module.scss";

export const SimpleGenericCard = ({
  title,
  description,
  image,
  linkTo,
  primaryAction,
  selected,
}) => {
  /**
   * Renders the Primary content in the card including the item's image and title as well as the name and image for the item's author/owner.
   */
  const renderPrimaryContent = () => {
    return (
      <>
        <CardMedia sixteenByNine style={{ backgroundImage: `url(${image}` }} />
        <div className={style.metaContainer}>
          <div className={style.metaTextContainer}>
            <Typography
              use="subtitle1"
              tag="h2"
              className={style.title}
              title={title}
            >
              {title}
            </Typography>
            <Typography
              use="subtitle2"
              theme="textSecondaryOnBackground"
              tag="h2"
              className={style.description}
              title={title}
            >
              {description}
            </Typography>
          </div>
        </div>
      </>
    );
  };

  /**
   * Renders the full contents of the card including the primary content, the isLocked layer (if isLocked), and the overflow menu.
   */
  const renderCardContent = () => {
    if (linkTo) {
      return (
        <>
          <Link to={linkTo} className={globalStyle.linkNoStyle}>
            <CardPrimaryAction>{renderPrimaryContent()}</CardPrimaryAction>
          </Link>
        </>
      );
    } else
      return (
        <>
          <CardPrimaryAction onClick={primaryAction}>
            {renderPrimaryContent()}
          </CardPrimaryAction>
        </>
      );
  };

  return (
    <div className={style.container}>
      <Card outlined className={selected ? style.cardSelected : null}>
        {renderCardContent()}
      </Card>
    </div>
  );
};
SimpleGenericCard.defaultProps = {
  title: "Untitled",
  // image: // ToDo: Default image if one isn't supplied
};

SimpleGenericCard.propTypes = {
  /**
   * The title of the activity. If not provided the title will defaults to `Untitled Activity`.
   */
  title: PropTypes.string.isRequired,
  /**
   * The title of the activity. If not provided the title will defaults to `Untitled Activity`.
   */
  description: PropTypes.string.isRequired,
  /**
   * The url to the channel image. Optional.
   */
  image: PropTypes.string,
  /**
   * The path this card should link to. Can also be a React-Router link object.
   */
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
