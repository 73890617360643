import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import isCidr from "is-cidr";
import { isIP } from "is-ip";

import { DialogContent, DialogActions, DialogButton } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import { InputErrorMessage } from "../../Auth/AuthInputFields";

import { snackbarQueue } from "../../Snackbar/snackbarQueue";
import Actions from "../../../Redux/actions";
import { convertDateToyyyMMdd, getDateEpochFromString } from "../../../utils/helpers";

const EditIpRangeForm = ({
  channelId,
  id,
  name,
  referenceId,
  range,
  start,
  end,
  closeDialog
}) => {
  const { register, handleSubmit, watch, reset, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      name,
      referenceId,
      range,
      start: convertDateToyyyMMdd(start),
      end: convertDateToyyyMMdd(end),
    },
  });
  const { isDirty, isValid } = formState;
  const [rangedataChanged, setRangedataChanged] = useState(false);
  const dispatch = useDispatch();
  const watchName = watch("name");
  const watchReferenceId = watch("referenceId");
  const watchRange = watch("range");
  const watchStart = watch("start");
  const watchEnd = watch("end");

  // This effect checks for user changes to the activity metadata form and update the setMetadataChanged state property.
  useEffect(() => {
    if (
      watchName !== name ||
      watchReferenceId !== referenceId ||
      watchRange !== range ||
      watchStart !== start ||
      watchEnd !== end
    ) {
      setRangedataChanged(true);
    } else {
      setRangedataChanged(false);
    }
  }, [
    watchName,
    watchReferenceId,
    watchRange,
    watchStart,
    watchEnd,
    name,
    referenceId,
    range,
    start,
    end,
  ]);

  const styleFullWidth = { width: "100%" };

  useEffect(() => {
    reset({
      name,
      referenceId,
      range,
      start: convertDateToyyyMMdd(start),
      end: convertDateToyyyMMdd(end),
    });
  }, [reset, id, name, referenceId, range, start, end]);

  // Custom validation function for IP ranges
  const validateIpRange = (value) => {
    const ranges = value.split(", ").map((range) => range.trim());

    for (const range of ranges) {
      if (isIP(range)) continue; // Single IP is valid
      if (isCidr(range)) continue; // CIDR is valid
      return "Each entry must be a valid single IP or CIDR.";
    }
    return true;
  };

  const renderReferenceId = () => {
    const inputLabel = "Reference ID";
    const inputName = "referenceId";
    const inputType = "text";
    const inputMaxLength = 80;
    const validationErrMsg = {
      // required: "Reference ID is required.",
      maxLength: `Reference ID must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        // data-mdc-dialog-initial-focus // will focus this input initially after the dialog has opened.
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderName = () => {
    const inputLabel = "Name";
    const inputName = "name";
    const inputType = "text";
    const inputMaxLength = 80;
    const validationErrMsg = {
      required: "Name is required.",
      maxLength: "Name must be 80 characters or less.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderRange = () => {
    const inputLabel = "Range";
    const inputName = "range";
    const inputType = "text";
    const inputMaxLength = 5000;
    const validationErrMsg = {
      maxLength: `The range must be less than ${inputMaxLength} characters.`,
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          validate: validateIpRange,
        })}
        invalid={errors[inputName]}
        textarea
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderStartDate = () => {
    const inputLabel = "Start";
    const inputName = "start";
    const inputType = "date";
    const validationErrMsg = {
      required: "Start date is required.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderEndDate = () => {
    const inputLabel = "End";
    const inputName = "end";
    const inputType = "date";
    const validationErrMsg = {
      required: "End date is required.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const onSubmit = async (data) => {
    let callback = (result) => {
      if (result.success) {
        snackbarQueue.notify({
          title: <b>Success!</b>,
          body: `IP range was updated`,
        });
      } else {
        snackbarQueue.notify({
          title: <b>Uh oh!</b>,
          body: "Unable to add IP range",
        });
      }
    };

    const startEpoch = getDateEpochFromString(data.start);
    const endEpoch = getDateEpochFromString(data.end);  

    dispatch(
      Actions.Channels.updateIpRangeToChannel(
        channelId,
        id,
        data.referenceId,
        data.name,
        data.range,
        startEpoch,
        endEpoch,
        callback
      )
    );

    closeDialog();
  };

  const isSubmitDisabled = () => {
    let submitDisabled = true;
    if (isDirty && isValid) {
      submitDisabled = false;
    } else submitDisabled = true;
    return submitDisabled;
  };

  const renderDialogContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: "0px" }}>
          <div>{renderName()}</div>
          <div>{renderReferenceId()}</div>
          <div>{renderRange()}</div>
          <div>{renderStartDate()}</div>
          <div>{renderEndDate()}</div>
        </DialogContent>

        <DialogActions>          
          <DialogButton action="cancel" type="button">
            Discard
          </DialogButton>
          <DialogButton
            type="submit"
            isDefaultAction
            disabled={isSubmitDisabled() || !rangedataChanged}
          >
            Save
          </DialogButton>
        </DialogActions>
      </form>
    );
  };

  return <>{renderDialogContent()}</>;
};

export default EditIpRangeForm;
