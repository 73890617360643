import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../Redux/actions";

export function useChannelIPRanges(channelId) {
  //States
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const [channelIPRanges, setChannelIPRanges] = useState([])

  //Hooks
  const channelList = useSelector((state) => state.Channels.list);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!fetching && !error && channelId) {
      if (!channelList.hasOwnProperty(channelId) || !channelList[channelId].activities) {

        setFetching(true);
        dispatch(
          Actions.Channels.fetchAChannel(channelId, (resp) => {
            if (!resp.success) {
              setError(resp.msg);
            }
            setFetching(false);
          })
        );
      } else {       
        if(channelList[channelId].ip_ranges){
            setChannelIPRanges([...channelList[channelId].ip_ranges]);
        }   
      }
    }
  }, [channelId, dispatch, channelList, fetching, error]);  

  return {channelIPRanges};
}
