import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { isIP } from "is-ip";
import { containsCidr } from "cidr-tools";

import style from "./SimpleActivity.module.scss";

import PageLoadingIndicator from "../../components/PageLoadingIndicator/PageLoadingIndicator";
import PlayerShadowDom from "../../components/PlayerShadowDom/PlayerShadowDom";
import PageError from "../../components/Error/PageError";

import { useActivity } from "../../hooks/useActivity";
import CustomPlayerShadowDom from "../../components/PlayerShadowDom/CustomPlayerShadowDom";
import Player2ShadowDom from "../../components/PlayerShadowDom/Player2ShadowDom";
import { useUserState } from "../../hooks/useUserState";
import { useChannelRole } from "../../hooks/Channel/useChannelRole";
import { useChannel } from "../../hooks/Channel/useChannel";
import { LoginEnum } from "../../Redux/Users/UserTypes";

const SimpleActivity = () => {
  const { activityId } = useParams();

  const [generalErrorMessage, setGeneralErrorMessage] = useState("");
  const [generalError, setGeneralError] = useState(false);
  const [channelId, setChannelId] = useState("");
  const [userIp, setUserIp] = useState(null);
  const [accessAllowed, setAccessAllowed] = useState(false);
  const [loginStatus, setLoginStatus] = useState("init");

  const userMeta = useUserState();
  const { role } = useChannelRole(channelId);
  const {
    channel,
    error: getChannelError,
    isFetchingChannel,
  } = useChannel(channelId);
  const { activity, error: activityError } = useActivity(activityId);

  //Effect hook for is logged in
  useEffect(() => {
    if (loginStatus !== userMeta.loginStatus) {
      setLoginStatus(userMeta.loginStatus);
    }
  }, [userMeta.loginStatus, loginStatus]);

  useEffect(() => {
    if (activity) {
      setChannelId(activity.channel);
    }
  }, [activity]);

  useEffect(() => {
    if (activityError) {
      setGeneralErrorMessage("The requested activity could not be found.");
      setGeneralError(true);
    }
  }, [activityError]);

  // Fetch activities and validate access after channel data is available
  useEffect(() => {
    if (!isFetchingChannel && channel) {
      fetchClientIp();
    }
  }, [channel, isFetchingChannel]);

  // Fetch client IP using ipify API
  const fetchClientIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      setUserIp(response.data.ip);
    } catch (error) {
      setGeneralError(true);
      setGeneralErrorMessage("Failed to fetch client IP.");
    }
  };

  // Helper function to validate IP within CIDR range
  const checkIPInRange = (ip, rangeString) => {
    const ranges = rangeString.split(",").map((range) => range.trim());

    return ranges.some((range) => {
      if (isIP(range)) {
        // Exact IP match
        return ip === range;
      } else {
        // CIDR range match using cidr-tools
        return containsCidr([range], ip);
      }
    });
  };

  // Validate access based on conditions
  const validateAccess = useCallback(() => {
    if (!channel) return;

    // Public access - immediate allowance
    if (channel.simple_access === "public") {
      setAccessAllowed(true);
      return;
    }

    // If not IP-restricted, deny access
    if (channel.simple_access !== "ip_range") {
      setAccessAllowed(false);
      return;
    }

    // Check if the IP is within the allowed range and within valid time periods
    if (userIp && channel.ip_ranges) {
      const isValidRange = channel.ip_ranges.some((range) => {
        const ipInRange = checkIPInRange(userIp, range.ranges);
        const now = Date.now();
        return ipInRange && now >= range.start && now <= range.end;
      });

      if (isValidRange) {
        setAccessAllowed(true);
        return;
      }
    }

    if (loginStatus !== LoginEnum.isLoggedIn) {
      setAccessAllowed(false);
      return;
    }

    setAccessAllowed(["none", "owner", "admin", "author"].includes(role));
  }, [channel, userIp, loginStatus, role]);

  // Check access once the client IP is fetched
  useEffect(() => {
    if (userIp) {
      validateAccess();
    }
  }, [userIp, channel, validateAccess]);

  const renderContent = () => {
    if (generalError || getChannelError) {
      return renderGeneralError();
    } else if (activity && userIp) {
      if (accessAllowed) return renderActivity();
      return (
        <div className={style.accessDenied}>
          <h3>Access Denied</h3>
          <p>You do not have permission to access this channel.</p>
        </div>
      );
    } else {
      return renderLoading();
    }
  };

  const renderGeneralError = () => {
    return (
      <PageError error={generalError} errorMessage={generalErrorMessage} />
    );
  };

  const onPlayerCreated = (player) => {
    const activityOptions = {
      title: activity.title ? activity.title : "",
      canEdit: false,
    };

    player.LoadActivityFromJSONString(activity.data, activityOptions);
  };

  const renderActivity = () => {
    if (activity.data.includes("app_id")) {
      return (
        <CustomPlayerShadowDom
          size="embed"
          playerName={activity.app_mount_name}
          playerAssets={activity.app_files}
          shadowDom={true}
        />
      );
    } else if (activity?.isPlayerV2) {
      return (
        <Player2ShadowDom
          size="embed"
          playerOptions={{
            activityData: activity?.data,
            activityTitle: activity?.title,
            activityDescription: activity?.description,
            channelId: getChannelId(),
            activityId: activityId,
            canAuthor: false,
            isNewActivity: false,
          }}
        />
      );
    } else {
      return (
        <div>
          <PlayerShadowDom
            onPlayerCreated={(player) => onPlayerCreated(player)}
            size="embed"
          />
        </div>
      );
    }
  };

  const renderLoading = () => {
    return (
      <div className={style.loadingContainer}>
        <PageLoadingIndicator />
      </div>
    );
  };

  /**
   * Gets the channelId from the best sources available to this script.
   */
  function getChannelId() {
    if (!channelId) {
      if (activity && activity.channel) {
        return activity.channel;
      } else return null;
    } else return channelId;
  }

  return renderContent();
};

export default SimpleActivity;
