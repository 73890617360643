import { call, select, put } from "redux-saga/effects";
import * as ChannelTypes from "./ChannelTypes";
import Actions, {
  setFetchedOwner,
  setContent,
  setTotalUsers,
  setLinkedChannels,
  removeChannelSubscription_fromStore,
} from "./ChannelActions";
import { LoginEnum } from "../Users/UserTypes";
import { addUserChannel, removeUserChannel } from "../Users/UserActions";
import { FormActivityFromResponseData } from "../../sagas/activities";
import ActivityActions from "../../actions/activities";
import { sortAphabetically_forObjects } from "../../utils/helpers";
import {
  ReduxCollectionFromAPICollection,
  ReduxBundleFromAPIBundle,
} from "../Collections/CollectionTypes";

import * as TYPES from "../../actions/types";
import { prepChannelAppForState } from "../Apps/AppsTypes";

export const insertChannel = (requestModule) =>
  function* insertChannelSaga(action) {
    try {
      const state = yield select();

      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { payload } = action;

      const req = yield call(requestModule, {
        data: {
          ...payload,
          access_pattern: "private",
        },
        method: "post",
        path: "channels",
        process: true,
      });

      const newChannel = ChannelTypes.SetUpChannelFor_Redux(req.data);

      yield put(Actions.addChannels([newChannel]));
      yield put(addUserChannel(newChannel.id, "owner"));

      if (action.callback) {
        yield call(action.callback, { success: true, newChannel });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const fetchUpdateChannel = (requestModule, uploadImage) =>
  function* fetchUpdateChannelSaga(action) {
    try {
      const state = yield select();

      if (state.User.loginStatus !== LoginEnum.isLoggedIn) {
        throw new Error("User must be logged in");
      }

      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { payload } = action;
      const {
        name,
        profilePhoto,
        bannerPhoto,
        uid,
        access_pattern,
        embed_permission,
        join_code_status,
        view_collections,
        user_limit,
        total_users,
        simple_access,
        ip_ranges
      } = payload;

      if (!name) {
        throw new Error("action needs action.payload.name");
      }

      if (!uid) {
        throw new Error("action needs action.payload.uid");
      }

      const resp = yield call(requestModule, {
        data: {
          image: profilePhoto,
          banner: bannerPhoto,
          channel_name: name,
          access_pattern,
          embed_permission,
          join_code_status,
          view_collections,
          user_limit,
          total_users,
          simple_access,
          ip_ranges
        },
        method: "PATCH",
        path: `channels/${uid}`,
        process: true,
      });

      let channelData;
      resp.data.forEach((returnedData) => {
        if (returnedData.type === "channel") {
          channelData = returnedData;
        }
      });

      if (!channelData) {
        throw new Error("Failed to get channel back from response");
      }

      yield put(
        Actions.updateChannel(
          uid,
          channelData.channel_name,
          channelData.image,
          channelData.banner,
          channelData.access_pattern,
          channelData.embed_permission,
          channelData.join_code,
          channelData.join_code_status,
          channelData.view_collections,
          channelData.user_limit,
          channelData.total_users,
          channelData.simple_access,
          channelData.ip_ranges
        )
      );

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const getAChannel = (requestModule) =>
  function* getAChannelSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      const req = yield call(requestModule, {
        path: `channels/${channelId}`,
      });

      req.data.channel.subscriptions = [];
      let channelsToSubmit = [];

      for (let index = 0; index < req.data.subscriptions.length; index++) {
        let sub = req.data.subscriptions[index];

        if (sub.activities) {
          req.data.activities = [...req.data.activities, ...sub.activities];
        }

        const subscription = ChannelTypes.SetUpSubscriptionFor_Redux(sub);
        req.data.channel.subscriptions.push(subscription);
      }

      let channel = ChannelTypes.SetUpChannelFor_Redux(req.data.channel),
        activities = req.data.activities.map((activity) => activity.SK);
      channel.activities = activities;

      activities = [];
      for (let index = 0; index < req.data.activities.length; index++) {
        const activityData = req.data.activities[index];
        let activity = FormActivityFromResponseData(activityData);
        activities.push(activity);
      }

      yield put(ActivityActions.addActivities(activities));

      channelsToSubmit.push(channel);
      yield put(Actions.addChannels(channelsToSubmit));

      if (action.callback) {
        yield call(action.callback, { success: true, msg: "" });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const getAllChannels = (requestModule) =>
  function* getAllChannelsSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { offset, limit } = action.payload;

      if (!offset && offset !== 0) {
        throw new Error("offset cannot be null or empty");
      }

      if (!limit && limit !== 0) {
        throw new Error("limit cannot be null or empty");
      }

      const req = yield call(requestModule, {
        path: `/channels`,
        // query: { offset, limit },
      });

      let channels = [];
      req.data.channels.forEach((c) => {
        let channel = ChannelTypes.SetUpChannelFor_Redux(c);

        //For backwards compatability.
        if (!channel.access_pattern) {
          channel.access_pattern = c.public ? "public" : "private";
        }

        channels.push(channel);
      });

      channels = sortAphabetically_forObjects(channels, "name");

      yield put(Actions.addChannels(channels));

      //THIS WILL BE USED LATER WHEN WE ADD PAGINATION BACK
      // const total = req.data.channels.length;
      // let totalRetrieved = offset + limit;
      // let allHaveBeenRetrieved = false;
      // if (totalRetrieved >= total) {
      //   totalRetrieved = total;
      //   allHaveBeenRetrieved = true;
      // }

      yield put({
        type: ChannelTypes.CHANNELS_SET_RETRIEVED,
        payload: {
          total: req.data.channels.length,
          totalRetrieved: req.data.channels.length,
          allHaveBeenRetrieved: true,
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const getLtiChannels = (requestModule) =>
  function* getLtiChannelsSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { consumerKey } = action.payload;

      if (!consumerKey) {
        throw new Error("action needs action.payload.channconsumerKeyelId");
      }

      const req = yield call(requestModule, {
        path: `lmsUserChannels/${consumerKey}`,
      });

      const channels = [];
      req.data.forEach((channel) => {
        let newChannel = ChannelTypes.SetUpChannelFor_Redux(channel);
        channels.push(newChannel);
      });

      yield put({
        type: ChannelTypes.CHANNELS_ADD_LTI_CHANNELS,
        channels,
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });
    }
  };

export const getChannelMembers = (requestModule) =>
  function* getChannelMembersSaga(action) {
    const { channelId } = action.payload;
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      const req = yield call(requestModule, {
        path: `channels/${channelId}/users`,
      });

      const members = [];
      req.data.forEach((member, i) => {
        // name
        let name = "";
        if (member.hasOwnProperty("first_name")) {
          name += member.first_name;
        }
        if (member.hasOwnProperty("last_name")) {
          name += " " + member.last_name;
        }
        name = name.trim();

        // email
        let email = "";
        if (member.hasOwnProperty("email")) {
          email = member.email;
        } else {
          email = member.SK;
        }

        // role and status
        let role = "";
        let status = "accepted"; // start as joined
        let pendingHasBeenSet = false;
        if (member.hasOwnProperty("role")) {
          role = member.role;
        }

        if (member.hasOwnProperty("type")) {
          if (String(member.type).includes("pending")) {
            // this is a pending memeber
            status = "pending";
            pendingHasBeenSet = true;
          }
        }

        if (!pendingHasBeenSet && member.hasOwnProperty("status")) {
          if (String(member.status).includes("pending")) {
            // this is a pending memeber
            status = member.status;
            pendingHasBeenSet = true;
          }
        }

        // date
        let date = "";
        let dateFormatted = "";
        if (member.hasOwnProperty("timestamp")) {
          date = new Date(member.timestamp);

          dateFormatted = date.getFullYear();
          if (date.getMonth() + 1 < 10) {
            dateFormatted += "0";
          }
          dateFormatted += date.getMonth() + 1;
          if (date.getDay() < 10) {
            dateFormatted += "0";
          }
          dateFormatted += date.getDay();
        }
        members.push({
          id: member.SK,
          name,
          email,
          role,
          status,
          date: dateFormatted,
          timestamp: member.timestamp,
          actions: [],
        });
      });
      yield put({
        type: ChannelTypes.CHANNELS_SET_MEMBERS,
        payload: {
          channelId,
          members,
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channelsChannelIdUsersGet error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });
      yield put({
        type: ChannelTypes.CHANNELS_SET_MEMBERS,
        payload: {
          channelId,
          members: [],
        },
      });
    }
  };

export const deleteChannelMember = (requestModule) =>
  function* deleteChannelMemberSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;
      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      const { userId } = action.payload;
      if (!userId) {
        throw new Error("action needs action.payload.userId");
      }

      yield call(requestModule, {
        method: "DELETE",
        path: `channels/${channelId}/toplvl/deleteitem`,
        data: {
          delete_type: "user",
          user: userId,
        },
      });

      yield put(setTotalUsers(channelId, "subtract"));

      yield put({
        type: ChannelTypes.CHANNEL_FETCH_MEMBERS,
        payload: {
          channelId,
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("deleteChannelMember error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });
    }
  };

export const deleteChannel = (requestModule) =>
  function* deleteChannelSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      const state = yield select();
      let channelActivities = [];

      if (state.Channels.list.hasOwnProperty(channelId)) {
        channelActivities = [...state.Channels.list[channelId].activities];
      }

      yield call(requestModule, {
        method: "delete",
        path: `channels/${channelId}`,
      });

      if (channelActivities.length > 0) {
        for (let index = 0; index < channelActivities.length; index++) {
          const activity = channelActivities[index];
          yield put({
            type: TYPES.ACTIVITY_REMOVE,
            payload: {
              activityId: activity.id,
              channelId: action.payload.channelId,
            },
          });
        }
      }

      yield put(removeUserChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true, msg: "" });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const editJoinCode = (requestModule) =>
  function* editJoinCodeSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, status, reset } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      const req = yield call(requestModule, {
        method: "PUT",
        path: `channels/${channelId}/joincode`,
        data: { status, reset },
      });

      yield put(
        Actions.updateChannel(
          req.data.PK,
          req.data.channel_name,
          req.data.image,
          req.data.banner,
          req.data.access_pattern,
          req.data.embed_permission,
          req.data.join_code,
          req.data.join_code_status,
          req.data.view_collections,
          req.data.user_limit,
          req.data.total_users,
          req.data.simple_access,
          req.data.ip_ranges,
        )
      );

      if (action.callback) {
        yield call(action.callback, { success: true, channel: req.data });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const submitJoinCode = (requestModule) =>
  function* submitJoinCodeSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { code } = action.payload;

      if (!code) {
        throw new Error("code is required");
      }

      const resp = yield call(requestModule, {
        method: "POST",
        path: `channels/access`,
        data: { code },
      });

      const { PK: channelId, role } = resp.data;

      yield put(addUserChannel(channelId, role));
      yield put(Actions.fetchAChannel(channelId, action.callback));
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const joinChannel = (requestModule) =>
  function* joinChannelSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      yield call(requestModule, {
        method: "POST",
        path: `channels/public`,
        data: { channelId },
      });

      yield put(setTotalUsers(channelId, "add"));
      yield put(addUserChannel(channelId, "member"));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const leaveChannel = (requestModule) =>
  function* leaveChannelSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("channelId is required");
      }

      yield call(requestModule, {
        method: "DELETE",
        path: `channels/public`,
        data: { channelId },
      });

      yield put(setTotalUsers(channelId, "subtract"));
      yield put(removeUserChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      } else {
        throw err;
      }
    }
  };

export const updateChannelMemebersRole = (requestModule) => {
  return function* updateChannelMemebersRoleSaga(action) {
    try {
      const { channelId, role, user } = action.payload;

      yield call(requestModule, {
        method: "PUT",
        path: `channels/${channelId}/manageuser`,
        data: { role, user },
      });

      let state = yield select(),
        members = state.Channels.list[`${channelId}`].members;

      members = updateMembersList(members, role, user);

      yield put({
        type: ChannelTypes.CHANNELS_SET_MEMBERS,
        payload: {
          channelId,
          members,
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      if (action.callback) {
        yield call(action.callback, { success: false, err });
      } else {
        console.error(err);
      }
    }
  };

  function updateMembersList(members, role, user) {
    if (role === "owner") {
      return members.map((member) => {
        if (member.id === user) {
          member.role = "owner";
          member.status = "pending";
        }

        return member;
      });
    }

    return members.map((member) => {
      if (member.id === user) {
        member.role = role;
      }
      return member;
    });
  }
};

export const getChannelOwner = (requestModule) =>
  function* getChannelOwnerSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      let req = yield call(requestModule, {
        method: "GET",
        path: `channels/${channelId}/owner`,
      });

      const owner = req.data.owner;

      yield put(setFetchedOwner(channelId, owner));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("getChannelOwner error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const getChannelContent = (requestmodule) =>
  function* getChannelContentSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      let resp = yield call(requestmodule, {
        method: "GET",
        path: `channels/${channelId}/resources`,
      });

      const content = resp.data;

      //Form these up into data that is useful for us
      let collections = [],
        bundles = [],
        apps = [];

      content.forEach((resource) => {
        if (resource.type === "bundle") {
          const bundle = ReduxBundleFromAPIBundle(resource); //Form our bundle object
          bundles.push(bundle);
        }

        if (resource.type === "bundle-collection") {
          const collection = ReduxCollectionFromAPICollection(resource); //Form our collection object
          collections.push(collection);
        }

        if (resource.type === "channel-collection") {
          const collection = ReduxCollectionFromAPICollection(resource); //Form our collection object
          collections.push(collection);
        }

        if (resource.type === "channel-app") {
          const prepedApp = prepChannelAppForState(resource); //Form our app object
          apps.push(prepedApp);
        }
      });

      yield put(setContent(channelId, collections, bundles, apps));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("getChannelContent error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const channelAddBundle = (requestmodule) =>
  function* channelAddBundleSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, bundleId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!bundleId) {
        throw new Error("action needs action.payload.bundleId");
      }

      let res = yield call(requestmodule, {
        method: "POST",
        path: `channels/${channelId}/add/bundle/${bundleId}`,
      });

      let bundleLinkedTo = res.data;
      bundleLinkedTo = ReduxBundleFromAPIBundle(bundleLinkedTo);

      yield put(Actions.setAddBundleToChannel(channelId, bundleLinkedTo));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channelAddBundle error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const channelAddCollection = (requestmodule) =>
  function* channelAddCollectionSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, collectionId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!collectionId) {
        throw new Error("action needs action.payload.collectionId");
      }

      let res = yield call(requestmodule, {
        method: "POST",
        path: `channels/${channelId}/add/collection/${collectionId}`,
      });

      let collectionLinkedTo = res.data;
      collectionLinkedTo = ReduxCollectionFromAPICollection(collectionLinkedTo);
      yield put(
        Actions.setAddCollectionToChannel(channelId, collectionLinkedTo)
      );

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channelAddCollection error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const channelRemoveBundle = (requestmodule) =>
  function* channelRemoveBundleSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, bundleId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!bundleId) {
        throw new Error("action needs action.payload.bundleId");
      }

      yield call(requestmodule, {
        method: "DELETE",
        path: `channels/${channelId}/remove/bundle/${bundleId}`,
      });

      yield put(Actions.setRemoveBundleFromChannel(channelId, bundleId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channelRemoveBundle error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const channelRemoveCollection = (requestmodule) =>
  function* channelRemoveCollectionSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, collectionId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!collectionId) {
        throw new Error("action needs action.payload.collectionId");
      }

      yield call(requestmodule, {
        method: "DELETE",
        path: `channels/${channelId}/remove/collection/${collectionId}`,
      });

      yield put(
        Actions.setRemoveCollectionFromChannel(channelId, collectionId)
      );

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channelRemoveCollection error: ", err);
      yield put({
        // display error using user status
        type: ChannelTypes.CHANNELS_SET_USER_STATUS,
        status: "error",
        message: err,
      });

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const requestMoreUsers = (requestmodule) =>
  function* requestMoreUsersSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, user_limit } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!user_limit) {
        throw new Error("action needs action.payload.user_limit");
      }

      yield call(requestmodule, {
        method: "POST",
        path: `channels/${channelId}/requestUsers`,
        data: {
          user_limit: Number(action.payload.user_limit),
        },
      });

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channel requestMoreUsers error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const inviteUser = (requestmodule) =>
  function* inviteUserSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      let { channelId, name, email, role, userId, isResending } =
        action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      email = email.toLowerCase();

      yield call(requestmodule, {
        path: `channels/${channelId}/invite`,
        data: {
          name,
          email,
          role,
          userId,
        },
        query: {
          isResending,
        },
        method: "POST",
      });

      if (!isResending) {
        //if we are not resending an invite
        yield put(setTotalUsers(channelId, "add"));
      }

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("channel inviteUser error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const addChannelSubscription = (requestModule) =>
  function* addChannelSubscriptionSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, subscriptionId, canAuthor, canEdit, expirationDate } =
        action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!subscriptionId) {
        throw new Error("action needs action.payload.subscriptionId");
      }

      if (!expirationDate) {
        throw new Error("action needs action.payload.expirationDate");
      }

      yield call(requestModule, {
        method: "POST",
        path: `channels/${channelId}/subscription/${subscriptionId}`,
        data: {
          can_edit: canEdit,
          can_create: canAuthor,
          expiration: expirationDate,
        },
      });

      yield put(Actions.fetchAChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("addChannelSubscription error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const editChannelSubscription = (requestModule) =>
  function* editChannelSubscriptionSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, subscriptionId, canAuthor, canEdit, expirationDate } =
        action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      if (!subscriptionId) {
        throw new Error("action needs action.payload.subscriptionId");
      }

      if (!expirationDate) {
        throw new Error("action needs action.payload.expirationDate");
      }

      yield call(requestModule, {
        method: "PATCH",
        path: `channels/${channelId}/subscription/${subscriptionId}`,
        data: {
          can_edit: canEdit,
          can_create: canAuthor,
          expiration: expirationDate,
        },
      });

      yield put(Actions.fetchAChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("addChannelSubscription error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const getChannelLinkedChannels = (requestModule) =>
  function* getChannelLinkedChannelsSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId } = action.payload;

      if (!channelId) {
        throw new Error("action needs action.payload.channelId");
      }

      // todo fill in with actual api call
      /*
      let req = yield call(requestModule, {
        method: "GET",
        path: `channels/${channelId}/todo`
      });
      */

      // todo fill in with returned data:
      const data = { data: "data" }; //req.data.todo
      yield put(setLinkedChannels(channelId, data));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("getChannelLinkedChannels error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const deleteChannelSubscription = (requestModule) =>
  function* deleteChannelSubscriptionSaga(action) {
    try {
      //Check for a payload
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, subscriptionId } = action.payload;

      yield call(requestModule, {
        method: "DELETE",
        path: `channels/${channelId}/subscription/${subscriptionId}`,
      });

      yield put(removeChannelSubscription_fromStore(channelId, subscriptionId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("deleteChannelSubscription error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const addIpRange = (requestModule) =>
  function* addIpRangeSaga(action) {
    try {     
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, id, referenceId, name, range, start, end } =
        action.payload;

      yield call(requestModule, {
        method: "POST",
        path: `channels/${channelId}/ip-range`,
        data: {
          id,
          referenceId,
          name,
          ranges: range,
          start,
          end,
        },
      });

      yield put(Actions.fetchAChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("addIpRange error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const updateIpRange = (requestModule) =>
  function* updateIpRangeSaga(action) {
    try {    
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, id, referenceId, name, range, start, end } =
        action.payload;

      yield call(requestModule, {
        method: "PATCH",
        path: `channels/${channelId}/ip-range`,
        data: {
          id,
          referenceId,
          name,
          ranges: range,
          start,
          end,
        },
      });      

      yield put(Actions.fetchAChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("updateIpRange error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };

export const deleteIpRange = (requestModule) =>
  function* deleteIpRangeSaga(action) {
    try {
      if (!action.hasOwnProperty("payload")) {
        throw new Error("action needs action.payload");
      }

      const { channelId, id } = action.payload;

      yield call(requestModule, {
        method: "DELETE",
        path: `channels/${channelId}/${id}`       
      });     
      
      yield put(Actions.fetchAChannel(channelId));

      if (action.callback) {
        yield call(action.callback, { success: true });
      }
    } catch (err) {
      console.error("deleteIpRange error: ", err);

      if (action.callback) {
        yield call(action.callback, { success: false, msg: err });
      }
    }
  };