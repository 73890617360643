import React from "react";

import { Dialog, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import EditIpRangeForm from "./EditIpRangeForm";

const EditIpRangeDialog = ({
  open,
  onClose,
  renderToPortal,
  channelId,
  id,
  name,
  referenceId,
  range,
  start,
  end,
}) => {
  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
    >
      <DialogTitle>Edit IP Range</DialogTitle>
      <EditIpRangeForm
        channelId={channelId}
        id={id}
        referenceId={referenceId}
        name={name}
        range={range}
        start={start}
        end={end}
        closeDialog={onClose}
      />
    </Dialog>
  );
};

export default EditIpRangeDialog;
