import * as ChannelTypes from "./ChannelTypes";
import * as TYPES from "../../actions/types";

const initialState = {
  list: {},
  ltiChannels: {},
  totalChannelsCount: 0,
  channelsRetrieved: 0,
  allHaveBeenRetrieved: false,
};

const reducer = (state = initialState, action) => {
  let newChannelList = {};
  let currentChannelIDs = [];
  let newLtiChannelList = {};
  let channel = {};

  switch (action.type) {
    case ChannelTypes.CHANNELS_ADD_CHANNELS:
      return AddChannels(action.payload.channels, state);

    case ChannelTypes.CHANNELS_RESET:
      return {
        ...initialState,
      };

    case ChannelTypes.CHANNELS_ADD_LTI_CHANNELS:
      if (!action.hasOwnProperty("channels")) {
        throw new Error(
          ChannelTypes.CHANNELS_ADD_LTI_CHANNELS +
            " action needs action.channels"
        );
      }

      if (!Array.isArray(action.channels)) {
        throw Error(
          ChannelTypes.CHANNELS_ADD_LTI_CHANNELS +
            " action.channels must be an array"
        );
      }

      newChannelList = { ...state.list };
      newLtiChannelList = { ...state.ltiChannels };

      action.channels.forEach((channel) => {
        if (newChannelList.hasOwnProperty(channel.id)) {
          newChannelList[channel.id] = {
            ...newChannelList[channel.id], //Note, we keep the origianl attributes
          };
        } else {
          newChannelList[channel.id] = channel;
        }
        newLtiChannelList[channel.id] = "member";
      });

      return {
        ...state,
        list: newChannelList,
        ltiChannels: newLtiChannelList,
      };

    case TYPES.ACTIVITY_REMOVE:
      currentChannelIDs = Object.values(state.list);
      newChannelList = {};

      currentChannelIDs.forEach((channel) => {
        if (action.payload.channelId === channel.id) {
          newChannelList[channel.id] = {
            ...channel,
            activities: channel.activities.filter(
              (channelActivityID) =>
                channelActivityID !== action.payload.activityId
            ),
          };
        } else {
          newChannelList[channel.id] = {
            ...channel,
          };
        }
      });

      return {
        ...state,
        list: newChannelList,
      };

    case ChannelTypes.CHANNEL_DELETE:
      if (!action.hasOwnProperty("payload")) {
        throw new Error(
          ChannelTypes.CHANNEL_DELETE + " action needs action.payload"
        );
      }

      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_DELETE + " action needs action.payload.channelId"
        );
      }

      newChannelList = {};

      //Iterate over existing channels and only keep the ones that don't match the deleted channel id
      const existingChannels = Object.values(state.list);
      existingChannels.forEach((channel) => {
        if (channel.id !== action.payload.channelId) {
          //Keep it
          newChannelList[channel.id] = { ...channel };
        }
      });

      return {
        ...state,
        list: newChannelList,
      };

    case ChannelTypes.CHANNELS_ADD_ACTIVITIES:
      if (state.list.hasOwnProperty(action.payload.channelId)) {
        let newChannelActivityList = state.list[action.payload.channelId]
          .activities
          ? state.list[action.payload.channelId].activities
          : [];

        action.payload.activities.forEach((activityID) => {
          if (!newChannelActivityList.includes(activityID)) {
            newChannelActivityList.push(activityID);
          }
        });

        newChannelList = { ...state.list };
        newChannelList[action.payload.channelId] = {
          ...newChannelList[action.payload.channelId],
          activities: newChannelActivityList,
        };

        if (action.payload.allActivitiesFetched) {
          newChannelList[action.payload.channelId].allActivitiesFetched =
            action.payload.allActivitiesFetched;
        }

        return {
          ...state,
          list: newChannelList,
        };
      } else {
        return state;
      }

    case ChannelTypes.CHANNELS_UPDATE:
      const currentChannel = state.list[action.payload.channelId];
      if (!currentChannel) {
        console.warn("could not update channel");
        return state;
      } else {
        const updatedChannel = {
          ...currentChannel,
          name: action.payload.name,
          image_url: action.payload.image_url,
          banner_url: action.payload.banner_url,
          access_pattern: action.payload.access_pattern,
          embed_permission: action.payload.embed_permission,
          join_code: action.payload.join_code,
          join_code_status: action.payload.join_code_status,
          view_collections: action.payload.view_collections,
          user_limit: action.payload.user_limit,
          total_users: action.payload.total_users,
          simple_access: action.payload.simple_access,
          ip_ranges: action.payload.ip_ranges
        };

        newChannelList = { ...state.list };
        newChannelList[action.payload.channelId] = updatedChannel;
        return {
          ...state,
          list: newChannelList,
        };
      }

    case ChannelTypes.CHANNELS_SET_RETRIEVED:
      return {
        ...state,
        totalChannelsCount: action.payload.total,
        channelsRetrieved: action.payload.offset,
        allHaveBeenRetrieved: action.payload.allHaveBeenRetrieved,
      };

    case ChannelTypes.CHANNELS_SET_MEMBERS:
      if (!action.payload.hasOwnProperty("members")) {
        throw new Error(
          ChannelTypes.CHANNELS_SET_MEMBERS +
            " action needs action.payload.members"
        );
      }

      newChannelList = state.list;
      newChannelList[action.payload.channelId].members = action.payload.members;

      return {
        list: newChannelList,
        ...state,
      };

    case ChannelTypes.CHANNEL_SET_FETCHED_OWNER:
      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_FETCHED_OWNER +
            " action needs action.payload.channelId"
        );
      }
      if (!action.payload.hasOwnProperty("owner")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_FETCHED_OWNER +
            " action needs action.payload.owner"
        );
      }

      const channelToSetOwner = state.list[action.payload.channelId];

      if (!channelToSetOwner) {
        console.warn("could not get channel to set owner");
        return state;
      } else {
        channelToSetOwner.owner = action.payload.owner;
        newChannelList = { ...state.list };
        newChannelList[action.payload.channelId] = channelToSetOwner;

        return {
          ...state,
          list: newChannelList,
        };
      }

    case ChannelTypes.CHANNEL_SET_CONTENT:
      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_CONTENT +
            " action needs action.payload.channelId"
        );
      }
      if (!action.payload.hasOwnProperty("collections")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_CONTENT +
            " action needs action.payload.collections"
        );
      }
      if (!action.payload.hasOwnProperty("bundles")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_CONTENT +
            " action needs action.payload.bundles"
        );
      }

      return SetFetchedContent(
        state,
        action.payload.channelId,
        action.payload.collections,
        action.payload.bundles,
        action.payload.apps
      );

    case ChannelTypes.CHANNEL_SET_ADD_CONTENT_COLLECTION:
      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_ADD_CONTENT_COLLECTION +
            " action needs action.payload.channelId"
        );
      }
      if (!action.payload.hasOwnProperty("collection")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_ADD_CONTENT_COLLECTION +
            " action needs action.payload.collection"
        );
      }
      return SetAddContentCollection(
        state,
        action.payload.channelId,
        action.payload.collection
      );

    case ChannelTypes.CHANNEL_SET_ADD_CONTENT_BUNDLE:
      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_ADD_CONTENT_BUNDLE +
            " action needs action.payload.channelId"
        );
      }
      if (!action.payload.hasOwnProperty("bundle")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_ADD_CONTENT_BUNDLE +
            " action needs action.payload.bundle"
        );
      }
      return SetAddContentBundle(
        state,
        action.payload.channelId,
        action.payload.bundle
      );

    case ChannelTypes.CHANNEL_SET_REMOVE_CONTENT_COLLECTION:
      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_REMOVE_CONTENT_COLLECTION +
            " action needs action.payload.channelId"
        );
      }
      if (!action.payload.hasOwnProperty("collectionId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_REMOVE_CONTENT_COLLECTION +
            " action needs action.payload.collectionId"
        );
      }
      return SetRemoveContentCollection(
        state,
        action.payload.channelId,
        action.payload.collectionId
      );

    case ChannelTypes.CHANNEL_SET_REMOVE_CONTENT_BUNDLE:
      if (!action.payload.hasOwnProperty("channelId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_REMOVE_CONTENT_BUNDLE +
            " action needs action.payload.channelId"
        );
      }
      if (!action.payload.hasOwnProperty("bundleId")) {
        throw new Error(
          ChannelTypes.CHANNEL_SET_REMOVE_CONTENT_BUNDLE +
            " action needs action.payload.bundleId"
        );
      }
      return SetRemoveContentBundle(
        state,
        action.payload.channelId,
        action.payload.bundleId
      );

    case ChannelTypes.CHANNEL_SET_TOTAL_USERS:
      channel = state.list[action.payload.channelId];
      newChannelList = { ...state.list };

      if (action.payload.addOrSubtract === "add") {
        ++channel.total_users;
      } else if (action.payload.addOrSubtract === "subtract") {
        --channel.total_users;
      }
      newChannelList[`${channel.PK}`] = channel;

      return {
        list: newChannelList,
        ...state,
      };

    case ChannelTypes.CHANNEL_REMOVE_CHANNEL_SUBSCRIPTION_FROM_STORE:
      channel = state.list[action.payload.channelId];
      newChannelList = { ...state.list };
      let newSubscriptions = channel.subscriptions.filter(
        (subscription) => subscription.id !== action.payload.subscriptionId
      );
      channel.subscriptions = newSubscriptions;

      newChannelList[`${channel.PK}`] = channel;

      return {
        list: newChannelList,
        ...state,
      };

    case ChannelTypes.CHANNEL_SET_CHANNEL_APP:
      newChannelList = { ...state?.list };

      if (newChannelList[action.payload.channelId].channelApps) {
        newChannelList[action.payload.channelId].channelApps.push(
          action.payload.app
        );
      } else {
        newChannelList[action.payload.channelId].channelApps = [
          action.payload.app,
        ];
      }
      return {
        ...state,
        list: newChannelList,
      };

    case ChannelTypes.CHANNEL_REMOVE_CHANNEL_APP:
      newChannelList = { ...state?.list };

      let channelApps = newChannelList[action.payload.channelId].channelApps;

      newChannelList[action.payload.channelId].channelApps = channelApps.filter(
        (app) => app.id !== action.payload.appId
      );

      return {
        ...state,
        list: newChannelList,
      };

    default:
      return state;
  }
};

export default reducer;

function AddChannels(channels, state) {
  const newChannelList = { ...state.list };
  channels.forEach((channel) => {
    if (!newChannelList.hasOwnProperty(channel.id)) {
      newChannelList[channel.id] = channel;
    } else {
      newChannelList[channel.id] = {
        ...newChannelList[channel.id],
        ...channel,
      };
    }
  });

  return {
    ...state,
    list: newChannelList,
  };
}

function SetFetchedContent(state, channelId, collections, bundles, apps) {
  const newChannelList = { ...state.list };
  newChannelList[channelId] = {
    ...newChannelList[channelId],
    collections,
    bundles,
    channelApps: apps,
  };

  return {
    ...state,
    list: newChannelList,
  };
}

function SetAddContentCollection(state, channelId, collection) {
  let collectionList = state.list[channelId].collections;
  if (!collectionList) {
    collectionList = [];
  }

  let bundlesList = state.list[channelId].bundles;
  if (!bundlesList) {
    bundlesList = [];
  }

  collectionList.push(collection);
  return SetFetchedContent(state, channelId, collectionList, bundlesList);
}

function SetAddContentBundle(state, channelId, bundle) {
  let collectionList = state.list[channelId].collections;
  if (!collectionList) {
    collectionList = [];
  }

  let bundlesList = state.list[channelId].bundles;
  if (!bundlesList) {
    bundlesList = [];
  }

  bundlesList.push(bundle);
  return SetFetchedContent(state, channelId, collectionList, bundlesList);
}

function SetRemoveContentCollection(state, channelId, collectionId) {
  let collectionList = state.list[channelId].collections;
  if (!collectionList) {
    collectionList = [];
  }

  let bundlesList = state.list[channelId].bundles;
  if (!bundlesList) {
    bundlesList = [];
  }

  collectionList = collectionList.filter((col) => col.id !== collectionId);
  return SetFetchedContent(state, channelId, collectionList, bundlesList);
}

function SetRemoveContentBundle(state, channelId, bundleId) {
  let collectionList = state.list[channelId].collections;
  if (!collectionList) {
    collectionList = [];
  }

  let bundlesList = state.list[channelId].bundles;
  if (!bundlesList) {
    bundlesList = [];
  }

  bundlesList = bundlesList.filter((bun) => bun.id !== bundleId);
  collectionList = collectionList.filter((col) => col?.bundleId !== bundleId);
  return SetFetchedContent(state, channelId, collectionList, bundlesList);
}
