import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useParams and useLocation

import { Typography } from "@rmwc/typography"; // Sass imported in main. CSS not needed.

import { FlexGrid } from "../../../components/Layout/Layout";
import { sortInAllDirections } from "../../../utils/helpers";
import { useChannel } from "../../../hooks/Channel/useChannel";
import { SimpleGenericCard } from "../../../components/Cards/SimpleGenericCard/SimpleGenericCard";

export const SimpleActivityList = ({ activities, channelId }) => {
  const [selectedFilter] = useState("all");
  const [filterKeys, setFilterKeys] = useState([
    { name: "All channels", key: "all" },
  ]);
  const [sortDirection] = useState(1);
  const [activitiesList, setActivitieslist] = useState([]); // The final list to be displayed

  let channel = useChannel(channelId).channel;

  // Get current location (URL) using useLocation to check for query params
  const location = useLocation();

  // Check if the current URL has zspace=true (from query params)
  const currentUrlHasZSpace =
    new URLSearchParams(location.search).get("zspace") === "true";

  // Effect to build the array of filter keys if applicable
  useEffect(() => {
    if (!channel) {
      return;
    }

    let filterKeysToAdd = [
      { name: channel.name, key: channel.id, image: channel.image_url },
    ]; // init with own channel
    let keysAdded = [channel.id];
    activities.forEach((a) => {
      if (!keysAdded.includes(a.channel)) {
        const subscriptions = channel.subscriptions;

        if (subscriptions !== null && subscriptions !== undefined) {
          subscriptions.forEach((element) => {
            if (element.id === a.channel) {
              // found the subscription
              if (element.isActive && !element.isExpired) {
                // subscription is still valid
                const name = element.name;
                if (name !== null && name !== undefined) {
                  const image = element?.image;
                  filterKeysToAdd.push({ name, key: a.channel, image });
                  keysAdded.push(a.channel);
                }
              }
            }
          });
        }
      }
    });

    if (filterKeysToAdd.length === 1) {
      // Don't display filters if there's only one
      setFilterKeys([]);
    } else {
      // More than one channel
      let newFilterKeys = [{ name: "All channels", key: "all" }];
      filterKeysToAdd.forEach((k) => {
        newFilterKeys.push(k);
      });
      setFilterKeys(newFilterKeys);
    }
  }, [activities, channel]);

  // Effect to filter and sort the activities list
  useEffect(() => {
    // If filter is applied, remove any from list
    let workingList = [];
    if (selectedFilter === "all" || filterKeys.length <= 2) {
      //No filter needed, workingList is all activities
      workingList = [...activities];
    } else {
      //  Filter activities and add to workingList
      activities.forEach((a) => {
        if (a.channel === selectedFilter) {
          workingList.push(a);
        }
      });
    }

    // If sort direction, sort the list
    let listSorted = [];
    if (sortDirection) {
      listSorted = sortInAllDirections(
        workingList,
        "title",
        sortDirection,
        "channel_name"
      );
    } else {
      // If no sort direction, don't sort, just use list as is.
      listSorted = [...workingList];
    }

    // Set final filtered and sorted list
    setActivitieslist(listSorted);
  }, [activities, selectedFilter, sortDirection, filterKeys]);

  /**
   * The content displayed when the list is empty
   */
  const emptyListContent = () => {
    return (
      <Typography
        use="body1"
        tag="h2"
        style={{ textAlign: "center", flexGrow: "1", paddingTop: "24px" }}
      >
        This channel doesn't have any activities yet.
      </Typography>
    );
  };

  /**
   * Builds an array of list items in JSX
   */
  const buildListItems = () => {
    // Build aray of list items and returnsjsx
    return activitiesList.map((activity, index) => {
      if (activity.isHidden) {
        return null;
      }

      if (channel && channel.id !== activity.channel) {
        channel.subscriptions.forEach((sub) => {
          if (activity.channel === sub.id) {
            sub.image_url = sub.image;
          }
        });
      }

      // Define the linkTo prop which is used as the card's primary action
      let linkTo = {};
      if (activity.isPlayerV2) {
        let launchAs = "";
        if (activity.hasDraft && !activity.hasPublished) {
          launchAs = "draft";
        } else launchAs = "published";

        linkTo = {
          pathname: `/a/${activity.id}/simple${
            currentUrlHasZSpace ? "?zspace=true" : ""
          }`,
          state: {
            channelId,
            launchAs,
          },
        };
      } else {
        linkTo = {
          pathname: `/a/${activity.id}/simple${
            currentUrlHasZSpace ? "?zspace=true" : ""
          }`,
          state: { channelId },
        };
      }

      // Return the JSX for this list item to be added to the listItems array
      return (
        <React.Fragment key={activity.id}>
          <SimpleGenericCard
            title={activity.title}
            description={activity.description ?? ""}
            image={activity.image}
            linkTo={linkTo}
          />
        </React.Fragment>
      );
    });
  };

  const renderContent = () => {
    if (!activities || activities.length === 0) {
      // If no activities, build no list notice instead of an empty list
      return emptyListContent();
    } else {
      return (
        <>
          <FlexGrid style={{ marginTop: "8px" }}>{buildListItems()}</FlexGrid>
        </>
      );
    }
  };

  return renderContent();
};

SimpleActivityList.propTypes = {
  /**
   * The array containing the activities to be displayed in the list.
   */
  activities: PropTypes.array,

  /**
   * The unique id of the channel. Required.
   */
  channelId: PropTypes.string.isRequired,
};
