import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import isCidr from "is-cidr";
import { isIP } from "is-ip";

import { DialogContent, DialogActions, DialogButton } from "@rmwc/dialog";
import "@rmwc/dialog/styles";
import { TextField } from "@rmwc/textfield";
import "@rmwc/textfield/styles";

import { InputErrorMessage } from "../../Auth/AuthInputFields";

import { snackbarQueue } from "../../Snackbar/snackbarQueue";
import Actions from "../../../Redux/actions";
import { getDateEpochFromString } from "../../../utils/helpers";

const today = new Date().toISOString().split("T")[0];

const defaultValues = {
  referenceId: "",
  name: "",
  range: "",
  start: today,
  end: today,
};

const AddIpRangeForm = ({ channelId, closeDialog }) => {
  const { register, handleSubmit, errors, formState, reset } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });
  const { isDirty, isValid } = formState;

  const dispatch = useDispatch();

  const styleFullWidth = { width: "100%" };

  // Custom validation function for IP ranges
  const validateIpRange = (value) => {
    const ranges = value.split(", ").map((range) => range.trim());

    for (const range of ranges) {
      if (isIP(range)) continue; // Single IP is valid
      if (isCidr(range)) continue; // CIDR is valid
      return "Each entry must be a valid single IP or CIDR.";
    }
    return true;
  };

  const renderReferenceId = () => {
    const inputLabel = "Reference ID";
    const inputName = "referenceId";
    const inputType = "text";
    const inputMaxLength = 80;
    const validationErrMsg = {
      // required: "Reference ID is required.",
      maxLength: `Reference ID must be ${inputMaxLength} characters or less.`,
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        // required
        type={inputType}
        label={inputLabel}
        name={inputName}
        // data-mdc-dialog-initial-focus // will focus this input initially after the dialog has opened.
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
            maxLength: {
              value: inputMaxLength,
              message: validationErrMsg.maxLength,
            },
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderName = () => {
    const inputLabel = "Name";
    const inputName = "name";
    const inputType = "text";
    const inputMaxLength = 80;
    const validationErrMsg = {
      required: "Name is required.",
      maxLength: "Name must be 80 characters or less.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
        })}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderRange = () => {
    const inputLabel = "Range";
    const inputName = "range";
    const inputType = "text";
    const inputMaxLength = 5000;
    const validationErrMsg = {
      maxLength: `The range must be less than ${inputMaxLength} characters.`,
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // className={style.inputFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={register({
          required: validationErrMsg.required,
          maxLength: {
            value: inputMaxLength,
            message: validationErrMsg.maxLength,
          },
          validate: validateIpRange,
        })}
        invalid={errors[inputName]}
        textarea
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderStartDate = () => {
    const inputLabel = "Start";
    const inputName = "start";
    const inputType = "date";
    const validationErrMsg = {
      required: "Start date is required.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const renderEndDate = () => {
    const inputLabel = "End";
    const inputName = "end";
    const inputType = "date";
    const validationErrMsg = {
      required: "End date is required.",
    };

    return (
      <TextField
        // general style props
        outlined
        style={styleFullWidth}
        // value, configuration, validation props
        required
        type={inputType}
        label={inputLabel}
        name={inputName}
        inputRef={(e) => {
          register(e, {
            required: validationErrMsg.required,
          });
        }}
        invalid={errors[inputName]}
        helpText={{
          validationMsg: true,
          persistent: false,
          children: <InputErrorMessage errors={errors} inputName={inputName} />,
        }}
      />
    );
  };

  const onSubmit = async (data) => {
    let callback = (result) => {
      if (result.success) {
        snackbarQueue.notify({
          title: <b>Success!</b>,
          body: `IP range was added`,
        });
      } else {
        snackbarQueue.notify({
          title: <b>Uh oh!</b>,
          body: "Unable to add IP range",
        });
      }
    };

    const startEpoch = getDateEpochFromString(data.start);
    const endEpoch = getDateEpochFromString(data.end);   

    dispatch(
      Actions.Channels.addIpRangeToChannel(
        channelId,
        uuidv4(),
        data.referenceId,
        data.name,
        data.range,
        startEpoch,
        endEpoch,
        callback
      )
    );

    reset({...defaultValues});
    closeDialog();
  };

  const isSubmitDisabled = () => {
    return !(isDirty && isValid);
  };

  const renderDialogContent = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent style={{ paddingBottom: "0px" }}>
          <div>{renderName()}</div>
          <div>{renderReferenceId()}</div>
          <div>{renderRange()}</div>
          <div>{renderStartDate()}</div>
          <div>{renderEndDate()}</div>
        </DialogContent>

        <DialogActions>
        <DialogButton action="cancel" type="button">
            Cancel
          </DialogButton>
          <DialogButton
            type="submit"
            isDefaultAction
            disabled={isSubmitDisabled()}
          >
            Add
          </DialogButton>          
        </DialogActions>
      </form>
    );
  };

  return <>{renderDialogContent()}</>;
};

export default AddIpRangeForm;
