import React from "react";

import { Dialog, DialogTitle } from "@rmwc/dialog";
import "@rmwc/dialog/styles";

import AddIpRangeForm from "./AddIpRangeForm";

const AddIpRangeDialog = ({ open, onClose, renderToPortal, channelId }) => {
  return (
    <Dialog
      open={open}
      onClose={(evt) => onClose(evt)}
      renderToPortal={renderToPortal}
    >
      <DialogTitle>Add IP Range</DialogTitle>
      <AddIpRangeForm channelId={channelId} closeDialog={onClose}/>
    </Dialog>
  );
};

export default AddIpRangeDialog;
