import React from "react";
import PropTypes from "prop-types";

import { Avatar } from "@rmwc/avatar";
import "@rmwc/avatar/styles";
import { Typography } from "@rmwc/typography"; // Sass imported into main so css import not needed.

import style from "./SimpleChannelHeader.module.scss";

export const SimpleChannelHeader = ({ channelName, channelImage }) => {
  const renderContent = () => {
    return (
      <div className={style.detailsContainer}>
        <div className={style.details}>
          <Avatar
            src={channelImage}
            style={{ width: "56px", height: "56px" }}
            name={channelName}
            className={style.avatar}
          />
          <Typography use="headline3" className={style.name}>
            {channelName}
          </Typography>
        </div>
      </div>
    );
  };
  return <div className={style.container}>{renderContent()}</div>;
};

SimpleChannelHeader.defaultProps = {
  channelName: "Untitled channel",
};

SimpleChannelHeader.propTypes = {
  /**
   * The url to the channel image. Optional.
   */
  channelImage: PropTypes.string,
  /**
   * The name of the channel. If not provided the name will
   * default to `Untitled channel`.
   */
  channelName: PropTypes.string,
};
